import TodoList from './TodoList';


function App() {
  return (
    <div>
      <TodoList />
    </div>
  );
}

export default App;
