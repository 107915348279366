import React, { useState, useEffect } from 'react';
import './App.css';

function TodoList() {
    const [tasks, setTasks] = useState([]); // Estado para almacenar tareas
    const [task, setTask] = useState(''); // Estado para el input de nueva tarea

    const fetchTasks = async () => {
        try {
            const response = await fetch('http://127.0.0.1:8000/api/v1/tareas/'); // URL de tu API
            if (!response.ok) {
                throw new Error(`Error HTTP: ${response.status}`);
            }
            const data = await response.json(); // Convertir la respuesta en JSON
            setTasks(data.data); // Actualizar el estado con las tareas obtenidas
        } catch (error) {
            console.error('Error al obtener las tareas:', error);
        }
    };

    // Obtener las tareas al cargar el componente
    useEffect(() => {
        fetchTasks();
    }, []);

    // Función para agregar una nueva tarea
    const addTask = async () => {
        const trimmedTask = task.trim();
        if (trimmedTask) {
            try {
                const response = await fetch('http://127.0.0.1:8000/api/v1/tareas/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ 
                        detalle: trimmedTask,
                        estados_id: 1 
                    }),
                });
                if (!response.ok) {
                    throw new Error(`Error HTTP: ${response.status}`);
                }
                const newTask = await response.json();
                setTasks([...tasks, newTask.data]); // Agregar la nueva tarea al estado
                setTask(''); // Limpiar el campo de entrada
            } catch (error) {
                console.error('Error al agregar la tarea:', error);
            }
        }
    };

    // Función para eliminar una tarea
    const deleteTask = async (id) => {
        try {
            const response = await fetch(`http://127.0.0.1:8000/api/v1/tareas/${id}`, {
                method: 'DELETE',
            });
            if (!response.ok) {
                throw new Error(`Error HTTP: ${response.status}`);
            }
            setTasks(tasks.filter((task) => task.id !== id)); // Eliminar la tarea del estado
        } catch (error) {
            console.error('Error al eliminar la tarea:', error);
        }
    };

    return (
        <div>
            <h3>My Todo List</h3>
            <input
                type="text"
                value={task}
                onChange={(e) => setTask(e.target.value)} // Actualizar el estado del input
                placeholder="Add a new task"
            />
            <button
                type="button"
                className="btn btn-success"
                onClick={addTask}
                style={{ marginLeft: '10px' }}
            >
                Add Task
            </button>
            <ul>
                {tasks.map((t) => (
                    <li key={t.id}>
                        {t.detalle}
                        <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => deleteTask(t.id)}
                            style={{ marginLeft: '10px' }}
                        >
                            Delete
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default TodoList;
